import axios from "axios";
import $store from '@/store'

const baseURL = '';

const service = axios.create({
    timeout: 10000,
    baseURL: baseURL,
    withCredentials: true,
});

// http request 拦截器
service.interceptors.request.use((config) => {
    if($store.state.user.user_info){
        config.headers.token = $store.state.user.user_info.token;
    }
    return config;
},(err) => {
    return Promise.reject(err);
});

// http response 拦截器
service.interceptors.response.use((response) => {
    const result = response.data;
    return result;
}, (error) => {
    return Promise.reject(error);
});

export default service;