<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
    </div>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style>
    html, body {
        margin: 0;
    }
    .container {
        width: 100%;
        min-height: 100vh;
        position: relative;
        background: #FFFFFF;
    }
    .flex {
        display: -webkit-flex;
        display: flex;
    }
    .inline-flex {
        display: -webkit-inline-flex;
        display: inline-flex;
    }
    .inline-flex.main-center,
    .flex.main-center {
        -webkit-justify-content: center;
        justify-content: center
    }
    .inline-flex.main-top,
    .flex.main-top {
        -webkit-justify-content: top;
        justify-content: top;
    }
    .inline-flex.main-bottom,
    .flex.main-bottom {
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
    }
    .inline-flex.main-justify,
    .flex.main-justify {
        -webkit-justify-content: space-between;
        justify-content: space-between;
    }
    .inline-flex.cross-top,
    .flex.cross-top {
        -webkit-align-items: flex-start;
        align-items: flex-start
    }
    .inline-flex.cross-center,
    .flex.cross-center {
        -webkit-align-items: center;
        align-items: center
    }
    .inline-flex.dir-top,
    .flex.dir-top {
        -webkit-flex-direction: column;
        flex-direction: column;
    }
    .inline-flex.flex-wrap,
    .flex.flex-wrap {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .inline-flex .flex-1,
    .flex .flex-1 {
        min-width: 0;
        -webkit-flex: 1;
        flex: 1;
    }
    input::placeholder {
        font-size: .3733rem;
        color: #C6C6C6;
    }
</style>
