import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible/index.js'
import moment from "moment"
import global from './global'
import store from './store'

Vue.prototype.$moment = moment
Vue.prototype.$global = global

Vue.config.productionTip = false


new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
