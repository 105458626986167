import { login_info } from "@/request/api";
import { Toast } from 'vant';

const user = {
    namespaced: true,
    state: () => ({
        user_info: null
    }),
    mutations: {
        setUserInfo(state, payload){
            state.user_info = payload.user_info;
        },
    },
    actions: {
        async getUserInfo({ commit }) {
            const loading = Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            const result = await login_info();
            loading.clear();
            if(result.code == 1){
                commit('setUserInfo', { user_info: result.data });
            }
        },
    },
    getters: {
        getUserInfo(state){
            return state.user_info;
        },
    }
}
export default user;