import { Toast } from 'vant';

const errorHandle = (err) => {
    if(err instanceof Error){
        return Toast(err.name + "：" + err.message);
    } else if(typeof err == 'object' && err.code){
        return Toast(err.msg);
    } else {
        return Toast(err.toString());
    }
}

const findItem = (data, key, val) => {
    let result = -1;
    data.forEach((item, index) => {
        if (item[key] == val) {
            result = index;
        }
    });
    return result;
}

export default {
    errorHandle,
    findItem,
}